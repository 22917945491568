<template>
  <div>
    <Modal
      v-model="showModalCreate"
      title="Criar novo faq"
      :enableClose="false"
    >
      <div class="form-group">
        <label for="pergunta">Pergunta</label>
        <input
          id="pergunta"
          v-model="item.pergunta"
          type="text"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="resposta">Resposta</label>
        <input
          id="resposta"
          v-model="item.resposta"
          type="text"
          class="form-control"
        />
      </div>
      <hr class="full-hr" />
      <div class="row">
        <div class="col-sm-12">
          <div class="float-right">
            <button
              type="button"
              class="btn btn-classic"
              @click.prevent="salvar"
            >
              Salvar
            </button>
            <button
              type="button"
              class="btn btn-secondary m-2"
              @click="showModalCreate = false"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">
              Faq
            </h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Faq
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9"></div>

                <div class="col-md-3 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button
                        class="btn btn-classic"
                        @click.prevent="openModalCreate"
                      >
                        <i class="fa fa-plus" /> NOVO
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
              <div class="accordion" role="tablist">
                <b-card
                  no-body
                  class="mb-1"
                  v-for="item in faqs"
                  :key="item.id"
                >
                  <b-card-header header-tag="header" class="p-1 color-proj-primary" role="tab">
                    <b-card-text block v-b-toggle.accordion-1 variant="info"
                      ><b>{{ item.pergunta }}</b></b-card-text
                    >
                  </b-card-header>
                  <b-collapse
                    id="accordion-1"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text> {{item.resposta }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import Swal from "sweetalert2";
import { checkUserHaveRole } from "@/tools";
import { constantes } from "@/constants";
import store from "@/store";
import Navigator from "../../../components/anos_turmas/AnosTurmasNavigator.vue";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    Modal: VueModal,
  },
  data() {
    return {
      showLeftMenu: true,
      faqs: [],
      item: {
        id: null,
        pergunta: "",
        resposta: "",
      },
      showModalCreate: false,
    };
  },
  computed: {},
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();
  },
  methods: {
    loadData() {
      this.$root.$emit("Spinner::show");
      this.$api.get("faqs").then((response) => {
        console.log(response.data);
        this.faqs = response.data;
        this.$root.$emit("Spinner::hide");
      });
    },
    salvar() {
      this.$root.$emit("Spinner::show");
      this.showModalCreate = false;
      this.$api
        .post("faqs", this.item)
        .then(() => {
          this.$root.$emit("Spinner::hide");

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Cadastrado com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.loadData();
            this.showModalCreate = false;
          });
        })
        .catch((error) => {
          this.$root.$emit("Spinner::hide");
          if (error.response.status == 400) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.detail +
                "</span>",
              showConfirmButton: false,
              timer: 3500,
            });
          } else if (error.response.status == 401) {
            store.dispatch("auth/ActionSignOut");
            window._Vue.$router.push({ name: "login" });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.violations[0].message +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.item = {};
    },
  },
};
</script>

<style></style>
