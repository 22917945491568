<template>
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-clean btn-action dropdown-toggle no-caret"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="toggleMenu"
    >
      <i class="fas fa-ellipsis-v" />
    </button>
    <div
      class="dropdown-menu dropdown-menu-right"
      :class="{ 'show' : showMenu}"
    >
      <button
        class="dropdown-item"
        type="button"
        @mouseout="showMenu = false"
      >
        <i><img :src="require('../../../assets/images/acoes/editar.svg')"></i>Editar
      </button>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      showMenu: false,
    }
  },
  methods: {
    toggleMenu(){
      this.showMenu = !this.showMenu;
    }
  }
}

</script>

<style>

</style>