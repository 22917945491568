<template>
  <div>
    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">
              Contato
            </h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Contato
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <!--<div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9"></div>

                <div class="col-md-3 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button class="btn btn-classic">
                         Novo
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>-->
          </div>

          <div class="row">
            <div class="col-sm-12">
              <!-- Content heare-->
              <div class="page-content__filter block__classic mb-2">
                <h5>
                  Email:
                  <a href="mailto:contato@editorauniavan.com.br" target="_blank"
                    >contato@editorauniavan.com.br
                  </a>
                </h5>
                <br />
                <h5>
                  Telefone:
                  <a href="tel:(47) 3363-1158" target="_blank"
                    >(47) 3363-1158
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import { constantes } from "@/constants";
export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
  },
  data() {
    return {
      showLeftMenu: true,
      contato: [],
    };
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();
  },
  methods: {
    loadData() {
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      // this.$root.$emit("Spinner::show");
      // /api/instituicao_ensinos/{id}
      // this.$api.get("instituicao_ensinos/"+idInstituicao).then((response) => {
      //   console.log(response.data);
      //   this.$root.$emit("Spinner::hide");
      // });
    },
  },
};
</script>

<style></style>
