<template>
  <div>
    <main-top-bar />
    <div
      class="d-flex main-wrapper"
      :class="{ 'toggled' : !showLeftMenu}"
    >
      <main-left-side-bar />

      <main
        id="content"
        class="main-content"
        role="main"
      >
        <div class="container-fluid pt-4 px-4">
          <div class="page-header d-flex justify-content-between align-items-center mb-3 pt-4">
            <h1 class="color-sec">
              Usuários
            </h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                >
                  Usuários
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          type="search"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-3 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button class="btn btn-classic">
                        <i class="fa fa-plus" /> NOVO
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table class="block__classic table table__classic table-striped table-borderless table-hover text-center mb-4">
                <thead>
                  <tr>
                    <th scope="col">
                      Nome
                    </th>
                    <th scope="col">
                      E-mail
                    </th>
                    <th scope="col">
                      Status
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-left pl-4">
                      Bruno Trindade
                    </td>
                    <td>bfredd@gmail.com</td>
                    <td>ATIVO</td>
                    <td>
                      <MenuListUserActions />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="8">
                      <a href="">LINK</a>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

import MainLeftSideBar from "@/components/menu/MainLeftSideBar"
import MainTopBar from "@/components/menu/MainTopBar";
import MenuListUserActions from "@/modules/usuarios/pages/MenuListUserActions";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    MenuListUserActions
  },
  data() {
    return {
      showLeftMenu: true,
      disciplinas: ['Ano', 'Turma'],
      disciplinaSelecionada: 'Ano'
    }
  },
  created() {
    this.$root.$on('Dashboard::toggleLeftMenu', () => { this.showLeftMenu = !this.showLeftMenu })
  }
}

</script>

<style>

</style>